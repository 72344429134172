import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '../store';


// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 8000                  // 请求超时时间
});
let isTokenValid = false // 标记是否token过期
// request拦截器
service.interceptors.request.use(config => {
  config.headers = {
    'Hkb': localStorage.getItem("token")
  }
  if (!config.data.userId) {
    config.data.userId = store.getters.userId
  }
  return config
}, error => {
  Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return Promise.resolve(res);
    }
    if (res.code == 200) {
      const response = res.data
      return Promise.resolve(response);
    } else if (res.code == 900) {
      if (!isTokenValid) {
        isTokenValid = true
        MessageBox.confirm('token无效,请重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.clear()
          location.reload();// 为了重新实例化vue-router对象 避免bug
        }).finally(() => {
          isTokenValid = false
        })
      }
    } else {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject();
    }
  },
  error => {
    if (error.message.includes('timeout')) {
      Message({
        message: '当前网络不可用,请稍后尝试',
        type: 'error',
        duration: 3 * 1000
      });
    } else {
      Message({
        message: '服务开小差了,请稍后尝试',
        type: 'error',
        duration: 3 * 1000
      });
    }
    return Promise.reject(error);
  }
)

export default service;
